<template>

  <div id="page-categorie_article-list">

    <vs-popup classContent="popup-example" title="Ajouter une Catégorie" :active.sync="popupCategorie_article">

        <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Abréviation Catégorie" v-validate="'required'" name="Abreg_Categ" v-model="Abreg_Categ" />
            <span class="text-danger text-sm"  v-show="errors.has('Abreg_Categ')">{{ errors.first('Abreg_Categ') }}</span>
         </div>
         <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Libellé Catégorie" v-validate="'required'" name="Libelle_Categ" v-model="Libelle_Categ" />
            <span class="text-danger text-sm"  v-show="errors.has('Libelle_Categ')">{{ errors.first('Libelle_Categ') }}</span>
         </div>
        </div>


        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatCategorie_article" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" status = "false" popupCategorie_articleUpdate = "false" >Annuler</vs-button>
          </div>
        </div>
      <!-- </vx-card> -->
    </vs-popup>

    <div class="vx-card p-6">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-table v-model="selected" stripe multiple pagination max-items="50" search :data="Categorie_articleData">
            <template slot="header">
              <!-- ACTION - DROPDOWN -->
              <vs-dropdown vs-trigger-click class="cursor-pointer">

                <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                  <span class="mr-2 leading-none">Actions</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Supprimer</span>
                    </span>
                  </vs-dropdown-item>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>CSV</span>
                    </span>
                  </vs-dropdown-item>

                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-button class="mb-4 ml-5 md:mb-0" color="primary" @click="popupCategorie_article=true">Ajouter une Catégorie</vs-button>
            </template>
            <template slot="thead">
              <vs-th>
                N°
              </vs-th>
              <vs-th sort-key="Abreg_Categ">
                Abréviation Catégorie
              </vs-th>
              <vs-th sort-key="Libelle_Categ">
                Libellé Catégorie
              </vs-th>
              <vs-th>
                Actions
              </vs-th>

            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td>
                  {{indextr + 1}}
                </vs-td>

                <vs-td :data="data[indextr].Abreg_Categ">
                  {{data[indextr].Abreg_Categ}}
                </vs-td>
                <vs-td :data="data[indextr].Libelle_Categ">
                  {{data[indextr].Libelle_Categ}}
                </vs-td>

                <vs-td>
                  <cell-renderer-actions :dataCategorie="tr" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import vSelect from 'vue-select'

// Store Module

// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'


export default {
  components: {
    vSelect,

    // Cell Renderer
    CellRendererActions
  },
  data () {
    return {
      Abreg_Categ: '',
      Libelle_Categ:'',
      popupCategorie_article : false,
      popupCategorie_articleUpdate: false,
      selected: []

    }
  },
  computed: {
    validateForm () {
      return  this.Libelle_Categ !== ''
    },

    Categorie_articleData () {
      return this.$store.state.categorie_article.categorie_articles
    }
  },
  methods: {
    creatCategorie_article () {
      const payload = {
        Abreg_Categ: this.Abreg_Categ,
        Libelle_Categ: this.Libelle_Categ
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('categorie_article/createCategorieArticle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.reset_data()
          this.$vs.loading.close()
          this.popupCategorie_article = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupCategorie_article = false
        })
    },
    reset_data () {

      this.Abreg_Categ = ''
      this.Libelle_Categ = ''

    },
    updateCategorie_article () {
      const payload = {
        Abreg_Categ: this.Abreg_Categ,
        Libelle_Categ: this.Libelle_Categ
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('categorie_article/updateCategorieArticle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.reset_data()
          this.$vs.loading.close()
          this.popupCategorie_article = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupCategorie_article = false
        })
    },

    getCategorieArticles () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })

    }
  },

  created () {
    this.getCategorieArticles()
  }

}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
