<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <vs-popup classContent="popup-example" title="Modifier la Catégorie" :active.sync="popupCategorie_articleUpdate">

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Abréviation Catégorie" v-validate="'required'" name="Abreg_Categ" v-model="Abreg_Categ" />
            <span class="text-danger text-sm"  v-show="errors.has('Abreg_Categ')">{{ errors.first('Abreg_Categ') }}</span>
          </div>

          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Libellé Catégorie" v-validate="'required'" name="Libelle_Categ" v-model="Libelle_Categ" />
            <span class="text-danger text-sm"  v-show="errors.has('Libelle_Categ')">{{ errors.first('Libelle_Categ') }}</span>
          </div>
        </div>


        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateCategorie_article" :disabled="!validateForm">Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="close" >Annuler</vs-button>
          </div>
        </div>

      </vs-popup>
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateCategorie_articleShowForm" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  props: ['dataCategorie'],
  data () {
    return {
      popupCategorie_articleUpdate: false,
      Abreg_Categ: '',
      Libelle_Categ:''

    }
  },
  computed:{
    validateForm () {
      return !this.errors.any() && this.Libelle_Categ !== ''
    }
  },
  methods: {
    close () {
      this.popupCategorie_articleUpdate = false
    },
    updateCategorie_articleShowForm () {
      this.Abreg_Categ = this.dataCategorie.Abreg_Categ
      this.Libelle_Categ = this.dataCategorie.Libelle_Categ
      this.popupCategorie_articleUpdate = true
    },
    updateCategorie_article () {
      const payload = {
        Abreg_Categ: this.Abreg_Categ,
        Libelle_Categ: this.Libelle_Categ,
        id: this.dataCategorie._id
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('categorie_article/updateCategorieArticle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.popupCategorie_articleUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupCategorie_articleUpdate = false
        })
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimer la Catégorie "${this.dataCategorie.Libelle_Categ}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$store.dispatch('categorie_article/deleteCategorieArticle', this.dataCategorie._id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        title: 'success',
        text: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })
    }
  }
}
</script>
